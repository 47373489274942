.mainn {
    padding: 0 50px;
}

.backgroundd {
    background: url(./assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}