.section2 {

    height: 100vh;
    color: white;
    displsay: flex;

}

.smtxt {
    font-family: 'Plus Jakarta Text', sans-serif;
}